import CheckIcon from '@mui/icons-material/Check';
import { Button, Chip, CircularProgress, List, ListItem, Theme, useMediaQuery } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AviaoBGInverse from 'assets/images/aviao-bg-inverse.svg';
import AviaoBG from 'assets/images/aviao-bg.svg';
import LinhaCurva from 'assets/images/linha-curva.svg';
import Logo from 'assets/images/logo-frontflow.svg';
import PainelImg from 'assets/images/painel-front-flow.svg';
import imgPorque from 'assets/images/porque-imagem.png';
import SetaBaixo from 'assets/images/seta-baixo.png';
import SetaDireita from 'assets/images/seta-direita.png';
import SetaEsquerda from 'assets/images/seta-esquerda.png';
import Triangulo from 'assets/images/triangulo.svg';
import axios from 'axios';
import BlockItem from 'components/blockItem';
import Footer from 'components/footer';
import FormContato from 'components/formContato';
import { useState } from 'react';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { connectPessoas, funcionalidades, porqueEscolher, titles } from 'textos';



const TextErp = () => {

    return (
        <Typography
            variant="h1"
            color="text.primary"
            gutterBottom
            fontWeight={800}
            fontSize={54}
            component="span"
            sx={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                fontSize: {xs: 40, md: 54},
                textAlign: {xs: "center", md: "start"},
                "&::after": {
                    content: '""',
                    backgroundImage: `url(${LinhaCurva})`,
                    width: 150,
                    height: 20,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                    // position: "absolute",
                    // bottom: 0,
                    // left: "50%",
                    // zIndex: -1,
                },
            }}
        >
            ERP's
        </Typography>
    )

}


const HomePage = () => {
    const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState<"ENVIADO" | "AGUARDANDO">("AGUARDANDO");

    const onSubmit = (data: any) => {
        setLoading(true);
        axios.post('https://frontflow.com.br/api/', data)
            .then((response) => {
                setStatus("ENVIADO");
            })
            .catch((error) => {
                Swal.fire({
                    title: "Erro!",
                    text: "Ocorreu um erro ao enviar o formulário, tente novamente mais tarde.",
                    icon: "error"
                });
            })
            .finally(() => {
                setLoading(false);
            });
    }



    return (
        <>
            <AppBar
                position="absolute"
                color="transparent"
                elevation={0}
            >
                <Container maxWidth={downSm ? false : "xl"}>
                    <Toolbar sx={{ flexWrap: 'wrap', mt: {xs: 1, md: 3}, textAlign: {xs: "center", md: "start"} }}>
                        <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                            <img src={Logo} alt="logo" />
                        </Typography>
                    </Toolbar>
                </Container>
            </AppBar>
            <main>
                {/* Hero unit */}
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        backgroundImage: `url(${Triangulo})`,
                        backgroundRepeat: 'no-repeat',
                        pt: {xs:10, md:8},
                        pb: 6,
                    }}
                    id="home"
                >
                    <Container maxWidth={false} component={Grid} container sx={{display: 'flex', p:"0!important", m:"0!important"}}>
                        <Grid item xs={12} md={6} sx={{pl: {xs: 0, md:30}, pt: {xs: 0, md: 15}}}>
                            <Typography
                                component="h1"
                                variant="h1"
                                color="text.primary"
                                gutterBottom
                                fontWeight={800}
                                sx={{ mt:5, mb:{xs: 4,md:3}, textAlign: {xs: "center", md: "start"}, fontSize: {xs: 40, md: 54}}}
                            >
                                {titles[0].title} <br /><Box sx={{display: 'flex', justifyContent: {xs: "center", md: "start"}}}><TextErp /></Box>
                            </Typography>
                            <Typography variant="h5" sx={{textAlign: {xs: "center", md: "start"}}} color="text.secondary" fontWeight={400} fontSize={20}>
                                {titles[0].subTitle}
                            </Typography>
                        </Grid>
                        <Grid item md={6} textAlign={"right"}>
                            <img src={PainelImg} alt="painel" width={"100%"} height={"100%"} />
                        </Grid>
                    </Container>
                </Box>
                <Container sx={{ py: {xs:0,md:1} }} maxWidth="xl" id="sobre" >
                    <Grid container>
                        <Grid item xs={12} sx={{ mb: {xs: 3,md:10}, }}>
                            <Typography
                                component="h2"
                                variant="h2"
                                align="center"
                                color="text.primary"
                                gutterBottom
                                fontWeight={800}
                                fontSize={45}
                                sx={{ px: {xs: 2,md:40} }}
                            >
                                {titles[1].title}
                            </Typography>
                        </Grid>
                        {connectPessoas.map((item: any, index: number) => (
                            <Grid
                                item
                                xs={12}
                                md={4}
                                key={index}
                                sx={{
                                    borderRight: {xs: "none" , md: index === 2 ? "none" : index === connectPessoas.length -1 ? "none" : "1px solid #BDDDF5"},
                                    mt: (index === 3 || index === 4 || index === 5) ? 4 : 0,
                                    p: 4
                                }}
                            >
                                <BlockItem
                                    title={item.title}
                                    image={item.imagem}
                                    description={item.description}
                                />
                            </Grid>
                        ))}

                    </Grid>
                </Container>
                <Container sx={{ py: 1, mt: {md:8} }} maxWidth="xl" id="beneficios" >
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <Typography
                                component="h2"
                                variant="h2"
                                align="left"
                                color="text.primary"
                                gutterBottom
                                fontWeight={800}
                                fontSize={50}
                                sx={{ p: 0 }}
                            >
                                {titles[2].title}
                            </Typography>
                            <List>
                                {porqueEscolher.map((item: any, index: number) => (
                                    <ListItem sx={{ mb: 3 }} disablePadding key={index}>
                                        <CheckIcon fontSize='large' sx={{ mr: 2, backgroundColor: "#0F8CE9", color: "#ffffff", p: 1, fontWeight: 700, borderRadius: 50 }} />
                                        <Typography fontSize={18} fontWeight={500}>{item}</Typography>
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                textAlign: {xs: "center", md: "right"},
                            }}
                            alignContent={'center'}
                        >
                            <img src={imgPorque} alt="porque" width={downSm ? 350 : 478} height={downSm ? "auto" : 455} />
                        </Grid>
                    </Grid>
                </Container>
                <Container maxWidth="xl" id="funcionalidades" sx={{mt:{xs: 4,md:8}}} >
                    <Box sx={{ 
                            background: 'linear-gradient(to right, rgba(79,156,249, .3) 4.97%, rgba(110,192,187, .3) 47.96%, rgba(97,133,75, .3) 82.62%)', 
                            minHeight: 310, 
                            borderRadius: 5, 
                            p: {xs:3,md:10},
                        }}
                    >   
                        {loading && (
                            <Box sx={{textAlign: 'center', display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                                <CircularProgress size={100} />
                                <Typography variant="h2" fontWeight={500} fontSize={20} sx={{my: 3}}>Enviando...</Typography>
                            </Box>
                        )}
                        {!loading && status === "AGUARDANDO" && <FormContato onSubmit={onSubmit} />}
                        {!loading && status === "ENVIADO" && (
                            <Box sx={{textAlign: 'center', display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                                <CheckIcon  sx={{fontSize: 50, width: 100, height: 100, backgroundColor: "#83C9B0", p: 2, borderRadius: "50%",mb:2}} />
                                <Typography variant="h2" fontWeight={800} fontSize={38} sx={{mb: 3}}>Contato enviado com sucesso!</Typography>
                                <Typography variant="h5" fontWeight={500} fontSize={24} sx={{mb: 3}} maxWidth={500}>Em breve, um dos nossos consultores entrará em contato com você.</Typography>
                                <Button variant="contained" sx={{ mt: 3, borderRadius: 3, p: 2, fontSize: 16, fontWeight: 700, color: '#ffffff', backgroundColor: '#000000' }} onClick={() => setStatus("AGUARDANDO")}>Enviar outro</Button>
                            </Box>
                        )}
                        
                    </Box>
                </Container>
                <Container 
                    maxWidth={false}  
                    sx={{
                        backgroundImage: `url(${AviaoBG}), url(${AviaoBGInverse})`,
                        backgroundRepeat: 'no-repeat, no-repeat',
                        backgroundSize: 'fit',
                        backgroundPosition: 'top right, bottom left'
                    }}>
                    <Container sx={{ py: 8 }} maxWidth={downSm ? false : "lg"} >
                        <Grid container>
                            <Grid 
                                item 
                                md={12} 
                                xs={12}
                                sx={{ 
                                    pr: {xs: 0, md: 50},
                                }}
                            >
                                <Typography
                                    component="h2"
                                    variant="h2"
                                    color="text.primary"
                                    gutterBottom
                                    fontWeight={800}
                                    sx={{ p: 0, mb:10, textAlign: {xs: "center", md: "start"}, fontSize: {xs: 30, md: 45}}}
                                >
                                    {titles[3].title}
                                </Typography>
                            </Grid>
                            <Grid item xs={5} />
                            {funcionalidades.map((item: any, index: number) => (
                                <Grid container item xs={12} key={index} sx={{ mb: 3, flexDirection: index % 2 === 0 ? 'row' : 'row-reverse' }}>
                                    <Grid item xs={12} md={7} sx={{p:5}}>
                                        <Box
                                            sx={{
                                                border: "1px solid #BDDDF5",
                                                borderBottomRightRadius: downMd ? index % 2 === 0 ? 15 : '22%' : index % 2 === 0 ? 15 : 0,
                                                borderTopRightRadius: downMd ? index % 2 === 0 ? 15 : '22%' : index % 2 === 0 ? 15 : 0,
                                                borderTopLeftRadius: downMd ? index % 2 === 0 ? "22%" : 15 : index % 2 === 0 ? 0 : 15,
                                                borderBottomLeftRadius: downMd ? index % 2 === 0 ? "22%" : 15 : index % 2 === 0 ? 0 : 15,
                                                borderLeft: index % 2 === 0 ? 'none' : "1px solid #BDDDF5",
                                                borderRight: index % 2 !== 0 ? 'none' : "1px solid #BDDDF5",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexDirection: index % 2 === 0 ? 'row' : 'row-reverse',
                                                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                                                backgroundColor: "#ffffff",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    padding: 2,
                                                    borderRadius: "50%",
                                                    border: "1px solid #BDDDF5",
                                                    maxWidth: downMd ? 160 : 192,
                                                    maxHeight: downMd ? 160 : 192,
                                                    position: "relative",
                                                    left: downMd ? index % 2 === 0 ? -25 : 25 : index % 2 === 0 ? -100 : 100,
                                                    backgroundColor: "#ffffff",
                                                }}
                                            >
                                                <Box sx={{
                                                    backgroundColor: "#AAC8DE",
                                                    borderRadius: "50%",
                                                    width:downMd ? 128 :  160,
                                                    height: downMd ? 128 :  160,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }} >
                                                    <img src={item.imagem} alt={item.title} width={downMd ? 60 : 120} height={ downMd ? 60 : 120} />
                                                </Box>
                                            </Box>

                                            <Box sx={{ 
                                                p: 1, 
                                                ml: downMd ? index % 2 === 0 ? -3 : 3 : index % 2 === 0 ? -10 : 3, 
                                                mr: downMd ? index % 2 !== 0 ? -3 : 0 : index % 2 !== 0 ? -10 : 3, 
                                                maxHeight: 192
                                            }}>
                                                <Typography
                                                    component={"div"}
                                                    color={"#0F8CE9"}
                                                    fontWeight={800}
                                                    sx={{p:0, mb:.5, fontSize: {xs: 16, md: 24}}}
                                                    lineHeight={1.2}
                                                >
                                                    {item.title} 
                                                    {index === funcionalidades.length - 1 && <Chip label="Em breve" size='small' sx={{backgroundColor: "#C4DCFA", color: "#4F9CF9", ml:1, fontSize: 10}} />}
                                                </Typography>
                                                <Typography
                                                    color={"#565758"}
                                                    sx={{fontSize: {xs: 12, md: 16}}}
                                                    fontWeight={400}
                                                    lineHeight={1.2}
                                                >
                                                    {item.description}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid 
                                        item
                                        xs={12}
                                        md={5}
                                        sx={{
                                            textAlign: index % 2 === 0 ? 'left' : 'right',
                                            display: "flex",
                                            justifyContent: index % 2 === 0 ? "flex-start" : "flex-end",
                                            alignItems: "end",
                                            position: "relative",
                                            top: 20,
                                        }}>
                                        {index !== funcionalidades.length - 1 && downMd && (
                                            <img src={SetaBaixo} alt="seta" />
                                        )}
                                        {index !== funcionalidades.length - 1 && !downMd && (
                                            <img src={index % 2 === 0 ? SetaDireita : SetaEsquerda} alt="seta" />
                                        )}
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                </Container>
            </main>
            <Box id="contato" />
            <Footer />
        </>
    );
}


export default HomePage;