import { Button, Grid, InputLabel, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { busca } from "textos";
import { PatternFormat } from 'react-number-format';

type Props = {
    onSubmit: (data: any) => void;
}


const FormContato = ({ onSubmit }: Props) => {
    const { handleSubmit, register, formState: { errors }, control } = useForm()

    return (
        <Grid container >
            <Grid item xs={12} sx={{ mb: { xs: 5, md: 0 } }} >
                <Typography
                    fontSize={38}
                    fontWeight={800}
                    lineHeight={1.1}
                    textAlign={"center"}
                >
                    {busca.title}
                </Typography>
            </Grid>
            <Grid item container xs={12} spacing={2} component="form" sx={{ mt: { xs: 0, md: 3 } }} onSubmit={handleSubmit(onSubmit)}>
                <Grid item xs={12} md={6}>
                    <InputLabel htmlFor="nome">
                        <Typography fontSize={18} fontWeight={500} color={'#000'}>Nome*</Typography>
                    </InputLabel>
                    <TextField
                        {...register("nome", { required: {value: true,message: "Campo obrigatório"}})}
                        error={!!errors.nome}
                        helperText={errors.nome ? "Campo obrigatório" : ""}
                        placeholder='Seu nome'
                        type="text"
                        variant="outlined"
                        fullWidth
                        // required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel htmlFor="email">
                        <Typography fontSize={18} fontWeight={500} color={'#000'}>Email*</Typography>
                    </InputLabel>
                    <TextField
                        {...register("email", { required: {value: true,message: "Campo obrigatório"}})}
                        error={!!errors.email}
                        helperText={errors.email ? "Campo obrigatório" : ""}
                        placeholder='Seu email'
                        type="email"
                        variant="outlined"
                        fullWidth
                        // required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel htmlFor="telefone">
                        <Typography fontSize={18} fontWeight={500} color={'#000'}>Telefone</Typography>
                    </InputLabel>
                    <Controller
                        name="telefone"
                        control={control}
                        render={({ field }) =>
                            <PatternFormat
                                format="(##) ##### ####"
                                mask="_"
                                customInput={TextField}
                                type="text"
                                variant="outlined"
                                fullWidth
                                placeholder='Seu telefone'
                                onChange={field.onChange}
                                value={field.value}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel htmlFor="empresa">
                        <Typography fontSize={18} fontWeight={500} color={'#000'}>Empresa*</Typography>
                    </InputLabel>
                    <TextField
                        {...register("empresa", { required: {value: true,message: "Campo obrigatório"}})}
                        error={!!errors.empresa}
                        helperText={errors.empresa ? "Campo obrigatório" : ""}
                        placeholder='Nome da sua empresa'
                        type="text"
                        variant="outlined"
                        fullWidth
                        sx={{
                            helpText: {
                                backgroundColor: 'red'
                            },
                        }}
                        // required
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" type='submit' fullWidth sx={{ mt: 3, borderRadius: 3, p: 2, fontSize: 16, fontWeight: 700, color: '#ffffff', backgroundColor: '#000000' }}>{busca.botao}</Button>
                </Grid>

            </Grid>
        </Grid>

    )

}



export default FormContato;