import WhiteLabel from 'assets/images/white-label.svg';
import Modular from 'assets/images/modular.svg';
import Economia from 'assets/images/economia.svg';
import IntegracaoOnline from 'assets/images/integracao-online.svg';
import MultiUsuario from 'assets/images/multi-usuario.svg';
import MultiFilial from 'assets/images/multi-filial.svg';
import RequisiãoMateriais from 'assets/images/requisicao-materiais.svg';
import GestaoCotacao from 'assets/images/gestao-cotacao.svg';
import ConsultaAprovacao from 'assets/images/consulta-aprovacao.svg';
import GestaoAgendamento from 'assets/images/gestao-agendamento.svg';
import Armazem from 'assets/images/armazem.svg';


export const titles = [
    {tipo: "banner", title: "Eleve o nível de experiência dos usuários na utilização de", subTitle: "O FrontFlow representa a solução perfeita para a integração eficiente de processos e colaboradores em sua empresa."},
    {tipo: "connect", title: "Conecte pessoas e processos em uma só plataforma", subTitle: ""},
    {tipo: "porque", title: "Porquê escolher o FrontFlow?", subTitle: ""},
    {tipo: "conheça", title: "Conheça as funcionalidades do FrontFlow", subTitle: ""}
]


export const connectPessoas = [
    {title: "White label", imagem: WhiteLabel, description: "A personalização da nossa ferramenta permite a inclusão do seu domínio, logotipo e cores, proporcionando aos usuários da sua empresa o acesso à nossa plataforma com a identidade visual da sua marca."},
    {title: "Modular", imagem: Modular, description: "Nossa plataforma segue uma abordagem modular, permitindo que você contrate apenas o que é essencial para as necessidades específicas da sua empresa."}, 
    {title: "Economia", imagem: Economia, description: "Ao centralizar os processos no FrontFlow, você reduz os gastos com licenças. As atividades são executadas de maneira simplificada e com custos mais baixos em nossa plataforma."}, 
    {title: "Integração online", imagem: IntegracaoOnline, description: "As informações são exibidas em tempo real, assegurando rapidez e confiabilidade. Não há mais preocupações com a integridade de dados entre os sistemas, pois todas as informações são apresentadas de maneira sincronizada."},
    {title: "Multiusuário", imagem: MultiUsuario, description: "Uma ferramenta abrangente que integra os processos de diversos tipos de usuários. Incluímos no sistema módulos destinados a diferentes perfis, como Fornecedores, Clientes e Funcionários, tanto internos quanto externos."},
    {title: "Multifilial", imagem: MultiFilial, description: "É possível gerenciar todo o seu grupo de empresas através de um único contrato e sistema. Os usuários podem acessar todas as filiais do grupo de maneira eficiente."}
]


export const porqueEscolher = [
    "Disponível a qualquer hora e lugar, reduzindo a burocracia",
    "Automatize tarefas de forma simples e ágil",
    "Reduza custos e digitalize principais fluxos de trabalho",
    "Tenha maior fluidez na operação"
]


export const busca = {
    title: "Busca reduzir a burocracia e automatizar tarefas de maneira fácil e eficiente? Entre em contato.",
    description: "Aprimore a rotina operacional conectando pessoas e processos por meio de uma plataforma única, acessível a qualquer momento e em qualquer lugar.",
    link: "https://via.placeholder.com/400x200",
    botao: "Enviar"
}


export const funcionalidades = [
    {title: "Requisição de materiais",imagem: RequisiãoMateriais , description: "A operação solicita os materiais necessários para a execução dos projetos, processos produtivos e/ou serviços diretamente para o armazém geral."},
    {title: "Gestão de cotações",imagem:GestaoCotacao , description: "A equipe de COMPRAS disponibiliza, de maneira online, as cotações a serem solicitadas pelos FORNECEDORES."},
    {title: "Consulta e aprovação de documentos",imagem:ConsultaAprovacao , description: "Rapidez para os GESTORES da sua empresa aprovarem documentos e operações do sistema de maneira online, diretamente na palma da mão."},
    {title: "Gestão de agendamento de entrega",imagem:GestaoAgendamento , description: "Os FORNECEDORES serão capazes de planejar as entregas de forma simples e totalmente integrada, alinhadas à sua janela de recebimento."},
    {title: "Armazém",imagem:Armazem , description: "A operação organiza, aloca e preserva de forma estratégica todos os itens essenciais, facilitando a localização eficiente, garantindo o controle de inventário e otimizando a gestão logística."},
]