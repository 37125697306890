import { Box, Typography } from "@mui/material"


type Props = {
    title: string,
    description: string,
    image: string
}


const BlockItem = ({title, description, image}: Props) => {
    return (
        <Box>
            <Box 
                width={82} 
                height={82} 
                sx={{
                    p:5, 
                    backgroundColor: '#D8E9F6', 
                    borderRadius: 50, 
                    textAlign: "center", 
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <img src={image} alt={title}  width={59} height={59}  />
            </Box>
            <Box>
                <Typography fontSize={22} fontWeight={700} textTransform={"capitalize"} sx={{mt:2}}>{title}</Typography>
                <Typography fontSize={16} fontWeight={400} color={"#565758"} sx={{mt:2}}>{description}</Typography>
            </Box>
        </Box>
    )
}


export default BlockItem