import {createTheme} from "@mui/material";
import {ptBR} from "@mui/material/locale";


export const theme = createTheme({
    typography: {
        fontFamily: 'Manrope',
    },
    palette: {
        
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '*': {
                    margin: 0,
                    padding: 0,
                    boxSizing: 'border-box',
                },
                body: {
                    overflowX: 'hidden',
                    // backgroundColor: "#f5f5f5",
                },

            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                    '& .MuiInputBase-root': {
                        backgroundColor: "#fff",
                    },
                    '& .MuiInputLabel-root': {
                        fontWeight: 600,
                        color: '#000',
                        '&.Mui-focused': {
                            backgroundColor: '#fff',
                            borderRadius: 3,
                            padding: '0 5px',
                        },
                    },
                    
                },
            },
        },
        
       
    },
}, ptBR);

//.css-5bdxvd-MuiInputBase-root-MuiFilledInput-root