import { Button, Container, Grid, Typography } from "@mui/material";
import facebook from 'assets/images/facebook.svg';
import instagram from 'assets/images/instagram.svg';
import linkedin from 'assets/images/linkedin.svg';
import whatsapp from 'assets/images/whatsapp.svg';
import frontflowBranca from 'assets/images/logo-frontflow-branca.svg';
// import { useForm } from "react-hook-form";
// import FrontFlow from "assets/images/f-front-flow.png";



const Footer = () => {
    // const {handleSubmit, register} = useForm()

    // const onSubmit = (data: any) => {
    //     console.log(data)
    // }


    return (
        <Container component={"footer"} maxWidth={false} sx={{ bgcolor: '#161C28', p: 6 }}>
            <Grid container sx={{px:{xs:0, md:30}}}>
                <Grid item xs={12} md={8} sx={{textAlign: {xs: "center", md: "left"}, mb: {xs: 3, md: 0}}}>
                    <img src={frontflowBranca} alt="FrontFlow" width={250} />
                </Grid>
                <Grid container item xs={12} md={4}>
                    <Grid item xs={12} sx={{mb:1}} textAlign={"center"}>
                        <Button variant="text" component="a" target="_blank" href="https://www.facebook.com/pontoini" sx={{mr: 1}}>
                            <img src={facebook} alt="facebook" height={40} />
                        </Button>
                        <Button variant="text" component="a" target="_blank" href="https://api.whatsapp.com/send/?phone=553135178905&text=Ol%C3%A1%2C+gostaria+de+mais+informa%C3%A7%C3%B5es%21&app_absent=0"  sx={{ mr: 1}}>
                            <img src={whatsapp} alt="whatsapp" height={40} />
                        </Button>
                        <Button variant="text" component="a" target="_blank" href="https://www.instagram.com/pontoini" sx={{ mr: 1}}>
                            <img src={instagram} alt="instagram" height={40} />
                        </Button>
                        <Button variant="text" component="a" target="_blank" href="https://www.linkedin.com/company/ponto-ini"  sx={{mr: 1}}>
                            <img src={linkedin} alt="linkedin" height={40} />
                        </Button>
                    </Grid>           
                    <Grid item xs={12} sx={{mb:3}}>
                        <Typography color={"#ffff"} textAlign={"center"} fontSize={16} fontWeight={300}>
                            © { new Date().getFullYear() } <a href="https://pontoini.com.br/" style={{color: "#ffff"}}
                                        target="_blank" rel="noreferrer">Ponto Ini</a>. Todos os direitos reservados.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid> 
        </Container>
    )
}


export default Footer